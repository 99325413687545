import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import PageWrapper from '../components/PageWrapper'
import SectionTitle from '../components/SectionTitle'
import Img from '../components/Img'
import FlippingImage from '../components/FlippingImage'

import mSquareLogo from '../assets/img/msquare_logo.svg'
import animal from '../assets/img/msquare/mamasquare/animal.jpg'
import animal_mask from '../assets/img/msquare/mamasquare/animal_mask.jpg'
import mamasquare_logo from '../assets/img/msquare/logo/mamasquare_logo.jpg'
import msquare_logo from '../assets/img/msquare/logo/msquare_logo_hp.jpg'
import breads2 from '../assets/img/msquare/logo/breads2.jpg'
import pain2 from '../assets/img/msquare/logo/pain2.jpg'
import kankitsukei2 from '../assets/img/msquare/material/kankitsukei2.jpg'
import sliced_citrus2 from '../assets/img/msquare/material/sliced_citrus2.jpg'
import ayatori_tag from '../assets/img/msquare/moto/ayatori_tag.jpg'
import kamifuusen_blue from '../assets/img/msquare/moto/kamifuusen_blue.jpg'
import origamidog from '../assets/img/msquare/request/origamidog.jpg'
import white_cat from '../assets/img/msquare/request/white_cat.jpg'
import dinosors from '../assets/img/msquare/objectives/dinosors.jpeg'
import origami from '../assets/img/msquare/objectives/origami.jpg'

import facebookLogo from '../assets/img/facebook.svg'
import instagramLogo from '../assets/img/instagram.svg'

import './msquare.sass'

const msquare = (props) => {
  return (
    <PageWrapper>
      <section className="mSquarePage">
        <SectionTitle title="M² Square" subtitle="ママスクエア"/>
        <div className="logoContainer">
          {/* <Img src={mSquareLogo} alt="エムスクエアのロゴ" title="エムスクエア"/> */}
        </div>
        <p className="jp-accent large mainParagraph text-center">
          作り手の顔が見える商品を使いたいお客様と、
          <br />
          仕立てスキルを活かして活躍するママがつながる。
        </p>
        <section className="mSquareSection flex">
          <div className="sectionContent">
            <h3>ママスクエア</h3>
            <p className="medium jp-accent">
              男の子柄、女の子柄、男の子色、女の子色、そんな区分をなくして自由に選んでもらいたい。
              <br />
              そんな想いで、性別問わず柄や色をお選びいただきやすいよう、デザイナーMynaが色鉛筆画を元に、デザインをしています。
              </p>
            <p className="medium jp-accent">
              ベビーの性別が分からないご出産祝いにもお選びいただいています。
              <br />
              オリジナル生地を、活躍の場が制限されてしまう仕立てスキルと経験のあるママ【在宅仕立てママ】たちが一つ一つ仕立てます。
            </p>
            <p className="medium jp-accent">
              ベビー服だけではなく、パパのワイシャツリメイク、マスク製作なども行います。
              <br />
              アイテムのリクエストや、保育園や幼稚園の指定に合わせたオーダーも承っています。
            </p>
            <p className="medium jp-accent">
              mama×mama＝M² SQUARE Mの二乗(ママスクエアと読みます)。
              <br />
              ママたちの力が掛け合わされて、一人ではできなかった新しいモノが生まれます。
            </p>
            <p className="medium jp-accent">
              M² SQUARE（エムスクエア)を前身とする少量生産、Made in Japanの
              <br />
              《忙しいママのライフスタイルを応援する》ブランドです。
            </p>
          </div>
          <div className="sectionImage">
            <div className="imageOuterContainer">
              <FlippingImage images={[animal, animal_mask]}/>
            </div>
          </div>
        </section>
        <section className="mSquareSection flex">
          <div className="sectionContent">
            <h3>身近な存在でありたい</h3>
            <p className="medium jp-accent">
              子育て中の忙しいママたちに代わって、ママが手作りしてくれたような「温もりのあるもの」を作ります。
              <br/>
              M² SQUARE（ママスクエア）アイテムを身に着けて、親子おそろいの柄でお出かけしてもらえたら、嬉しいです。手作りしたくてもなかなか手作りできない…急に必要になった出産祝いなどのギフト、ゆっくり選ぶ時間がない…そんな忙しいママたちのお手伝いができる身近な存在でありたいです。
              <br />
              ママスクエアでは、ご注文いただいてから、送られる方と使われる方のことを考えながら、在宅仕立てママがお作りし、検品＆検針機を通した後、お客様に直送いたします。
            </p>
          </div>
          <div className="sectionImage">
            <div className="imageOuterContainer">
              <div className="imageOuterContainer">
                <FlippingImage images={[ayatori_tag, kamifuusen_blue]}/>
              </div>
            </div>
          </div>
        </section>
        <section className="mSquareSection flex">
          <div className="sectionContent">
            <h3>ママスクエアの生地</h3>
            <p className="medium jp-accent">
              汗疹やアトピーに悩まされた私たちだから想うこと、 ベビーたちには同じ思いをさせたくない。 肌に優しいふんわり生地をお届けします。
              <br />
              オーガニックコットンの他、ダブルガーゼやコットンリネンなどの天然素材を中心に生地をご提案しています。
            </p>
            <p className="medium jp-accent">
              オリジナル生地は、ベビーの肌に触れても安心な染料を使っています。
              <br />
              空気をまとうふわふわの「ダブルガーゼ」は、 吸湿性とふんわり柔らかな肌触り。コットン100%で、汗かきで繊細な赤ちゃんのお肌に最適な素材。
            </p>
            <p className="medium jp-accent">
              ガーゼの柔らかさを大切に、内側の縫製始末を丁寧に行っています。 縫い合わせ部分をできるだけフラットに一つ一つ仕立てています。
            </p>
          </div>
          <div className="sectionImage">
            <div className="imageOuterContainer">
              <FlippingImage images={[kankitsukei2, sliced_citrus2]} delay={450}/>
            </div>
          </div>
        </section>
        <section className="mSquareSection flex">
          <div className="sectionContent">
            <h3>ロゴにこめた想い</h3>
            <p className="medium jp-accent">
              【エムスクエア】
              <br/>
              ちょっとモダンな和のモチーフのオリジナル柄、
              ブームを超えて世界でもたくさんの方に愛される定番柄になってほしい。
              <br />
              藍染のような紺色に折り紙風のロゴには、そんな想いが込められています。
            </p>
            <p className="medium jp-accent">
              【ママスクエア】
              <br/>
              エムスクエアの想いをつなぐ「ママスクエア」。
              <br/>
              エムスクエアの遺伝子が流れていることが視覚的にわかるようM² SQUAREと書いて「ママスクエア」と読んでもらいたくて発音記号を入れています。
              今後、スキルがあって在宅でお仕事を探しているママにたくさん加わってもらえることを願いながら、色々な色を放つ柔らかい虹のようなデザインにしました。

            </p>
          </div>
          <div className="sectionImage">
            <div className="imageOuterContainer">
              <FlippingImage images={[mamasquare_logo, msquare_logo]} contain delay={150}/>
            </div>
          </div>
        </section>
        <section className="mSquareSection flex">
          <div className="sectionContent">
            <h3>私たちの目指すところ</h3>
            <p className="medium jp-accent">
              スキルがあって働きたいのに働けない子育て中のママは想像以上にたくさんいます。
              <br/>
              ママスクエアの活動は、作り手の顔が見える商品を使いたいお客様と仕立てスキルを活かして活躍するママをつなぐ国内「エシカル」を目指しています。
              <br/>
              また、子育て中のママたちに【在宅仕立てママ】として、安定してお仕事をお願いできるような、社会的な意味を持つブランドに成長できることを目指しています。皆さまの応援を心よりお願いしたいです。
            </p>
          </div>
          <div className="sectionImage">
            <div className="imageOuterContainer">
              <FlippingImage images={[breads2, pain2]} delay={500}/>
            </div>
          </div>
        </section>
        <section className="mSquareSection flex">
          <div className="sectionContent">
            <h3>商品お取り扱いのご依頼やご提案</h3>
            <p className="medium jp-accent">
              M² SQUARE（ママスクエア）の商品は、現在、直接お客様にお届けする形をとっております。
              <br/>
              今後、【在宅仕立てママ】に継続してお仕事をお願いできるよう、セレクトショップや雑貨店、ギャラリーのオーナー様などにご協力いただき卸販売や、期間限定イベント、展示販売会などを通じて、より多くのお客様に商品をお届けしたいと考えています。
            </p>
            <p className="medium jp-accent">
              {/* <br/> */}
              ご依頼やご提案頂ける場合は、
              <Link to="/contact" className="text-red">お問い合わせ</Link>
              より、
              {/* <br/> */}
              詳細をお送りいただけますようお願いいたします。
            </p>
            {/* <p className="medium jp-accent">
              エムスクエアの商品は、一つ一つ仕立てているため、
              <br/>
              お客さまと丁寧に接していらっしゃるお店に
              <br/>
              お取り扱いいただけることを願っております。
              <br/>
              また、少量生産となりますため、
              <br/>
              すべてのお声がけにはお応えできない場合もございます。
              <br/>
              予めご了承いただけますようお願いいたします。
            </p> */}
          </div>
          <div className="sectionImage">
            <div className="imageOuterContainer">
              <FlippingImage images={[origamidog, white_cat, dinosors, origami]} delay={200}/>
            </div>
          </div>
        </section>
        <section className="mSquareSection sns flex">
          <div className="sectionContent">
            <h3 className="text-center">商品情報やイベント情報</h3>
            <p className="medium jp-accent text-center">
              SNSよりご覧ください。
            </p>
          </div>
          <div className="snsContainer flex justify-center">
            <a href="https://www.facebook.com/msquare.japan/" className="snsLogo facebookLogo" target="_blank" rel="noopener noreferrer">
              <Img src={facebookLogo} alt="Facebookへの風船アイコン" title="Facebookへ"/>
            </a>
            <a href="https://www.instagram.com/msquare.japan/" className="snsLogo instagramLogo" target="_blank" rel="noopener noreferrer">
              <Img src={instagramLogo} alt="Instagramへの風船アイコン" title="Instagramへ"/>
            </a>
          </div>
        </section>
      </section>
    </PageWrapper>
  )
}

export default memo(msquare)

msquare.propTypes = {}
