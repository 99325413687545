import React, { memo } from 'react'
import PropTypes from 'prop-types'
import './SectionTitle.sass'

const SectionTitle = ({ title, subtitle }) => {
  return (
    <div className="sectionTitle">
      <h1 className="accent text-center text-red">{title}</h1>
      <h2 className="regular jp-accent text-center">{subtitle}</h2>
    </div>
  )
}
export default memo(SectionTitle)

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired
}
SectionTitle.defaultProps = {

}
